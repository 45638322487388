* {
  scroll-behavior: smooth;
}
:root {
  --text-color: #1e1e1e;
  --color-dark: #1e1e1e;
  --body-bg: #fff;

  // alt color for dark theme
  --dark-bg: #1e1e1e;
  --dark-text-color: white;
}

// Default Global Style
$font-stack: "American Typewriter, serif";
$p-fontsize: 20px;
$global-color: conic-gradient(
  from 180deg at 50% 50%,
  #0aefff -69.37deg,
  #0f83ff 31.88deg,
  #b056e7 120deg,
  #ff9966 204.37deg,
  #0aefff 290.63deg,
  #0f83ff 391.87deg
);

$secondary-color: linear-gradient(45deg, #0f83ffd5, #af56e7bb);

.globalBtn {
  background: linear-gradient(45deg, #0f83ffd5, #af56e7bb);
  border: none !important;
  // height: 50px;
  font-weight: bold !important;
}
.globalBtn:hover {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #0aefffdf -69.37deg,
    #0f83ffd7 31.88deg,
    #af56e7e2 120deg,
    #ff9966e8 204.37deg,
    #0aefffe4 290.63deg,
    #0f83ffcb 391.87deg
  );
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;

  border: none;
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: $font-stack;
  h1,
  h2 {
    font-family: cursive;
    text-align: center;
    font-weight: bold !important;
  }

  h2 {
    padding-bottom: 3rem;
  }
}
#darkMode {
  display: none;
}
.wrapper {
  background: var(--body-bg);
  min-height: 100vh;
  color: var(--text-color);

  .dark-mode-toggle {
    top: 6rem;
    left: 2rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.5);
      transition: all 0.5s;
    }
  }
}

//apply drak mode
.dark-mode-check:checked + .wrapper {
  --body-bg: var(--dark-bg);
  --text-color: var(--dark-text-color);
}

// Header
.darkNav {
  h4 {
    color: white;
  }
  a {
    color: white !important;
  }
  .navbar-toggler {
    background-color: white !important;
  }
}

.headerNav {
  h4 {
    font-weight: bold;
  }
  a {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  a:hover {
    color: rgb(69, 69, 69);
  }
}
.navbar-brand {
  padding-top: 10px !important;
}
.fixed-top {
  background: linear-gradient(45deg, #0f83ff, #af56e7);
}
.nav-pad {
  padding-right: 20px !important;
}

// Hero
.leftContent {
  .globalBtn {
    margin-bottom: 15px;
  }
}
.hero {
  .heroContainer {
    padding-top: 4rem;
    position: relative;
    height: 100vh;
  }
  .col {
    display: flex;
    margin: auto;
    justify-content: flex-end;
  }
  .left {
    font-size: $p-fontsize;

    .tag {
      padding-bottom: 2rem;
    }

    @-webkit-keyframes Animation {
      0% {
        background-position: 10% 0%;
      }
      50% {
        background-position: 91% 100%;
      }
      100% {
        background-position: 10% 0%;
      }
    }
    @-moz-keyframes Animation {
      0% {
        background-position: 10% 0%;
      }
      50% {
        background-position: 91% 100%;
      }
      100% {
        background-position: 10% 0%;
      }
    }
    @keyframes Animation {
      0% {
        background-position: 10% 0%;
      }
      50% {
        background-position: 91% 100%;
      }
      100% {
        background-position: 10% 0%;
      }
    }
  }

  .right {
    position: relative;
    height: 100%;

    .gradient-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      filter: blur(160px);
      background: conic-gradient(
        from 180deg at 50% 50%,
        #0aefff -69.37deg,
        #0f83ff 31.88deg,
        #b056e7 120deg,
        #ff9966 204.37deg,
        #0aefff 290.63deg,
        #0f83ff 391.87deg
      );
      /* Adjust the gradient colors and opacity as needed */
    }
    .image {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      background-color: #ffffff96;
    }
    .hero-img-overlay {
      position: absolute;
      left: -150%;
      top: auto;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 370%;
      height: 5%;
      background-image: linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0),
        #ffffff54
      );
    }
  }
}

// Skills
// .skills{
//   margin-top: 2rem;
// }
.icons {
  svg {
    width: 50px;
    height: 50px;
    fill: #af56e79e;
  }
}

// about
.about {
  margin-top: 3rem;
  .tabs {
    border: none;
    margin-bottom: 2rem;
    button {
      font-size: 20px;
      font-weight: bold;
      color: black;
    }
    button.active {
      color: #af56e7;
      border-color: #af56e7;
    }
    button:hover {
      // color: #af56e7;

      border-color: #af56e7;
    }
  }
}
// contact
.contact {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 99999;
  padding-top: 2rem;
  padding-bottom: 3rem;

  .form {
    width: 80%;
    margin: auto;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.49);
  }
  .image {
    height: 90%;
    width: 75%;
  }
}
.contact::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $secondary-color;
  filter: blur(180px);
  z-index: -1;
}

// timeline
.timeline {
  font-size: 10px;
  color: rgb(53, 53, 53);
  .title {
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
  }
  .date {
    color: #af56e7;
    margin-top: 0;
  }

  h3 {
    padding-top: 0.25em;
  }
  .vertical-timeline.vertical-timeline--two-columns {
    width: 70%;
  }

  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    h4 {
      font-size: 20px;
      font-weight: bold;
    }
    h5 {
      font-size: 15px;
    }
    p {
      font-size: 13px;
    }
    .button {
      font-size: 12px;
    }
  }

  #description {
    margin: 0.5em 0 1em 0;
  }

  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
  }
  .vertical-timeline.vertical-timeline--two-columns::before {
    background-color: black;
  }
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
  .vertical-timeline.vertical-timeline--two-columns {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .vertical-timeline.vertical-timeline--two-columns {
    width: 90%;
  }
  .timeline .button {
    padding: 2px 0.5rem !important;
  }
  .hero .col {
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
  }
}

// projects
.projects {
  .card {
    margin-bottom: 30px;
  }
  .card-img-top {
    height: 250px;
    width: 100%;
  }
}

.footer {
  height: 50px;
  width: 100%;
  background: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
